import { FunctionComponent, useEffect } from 'react'
import { navigate } from 'gatsby'

const Error404Page: FunctionComponent = () => {
  useEffect(() => {
    void navigate('/')
  }, [])

  return null
}

export default Error404Page
